.nav__submenu {
  display: none;
}

.nav__menu-item:hover {
  .nav__submenu {
    display: block;
  }
}

/*
  Layout styles.
  
  I like to work on stuff that's good looking so I remixed a cool simple menu by Mike Rojas (thanks!): https://codepen.io/mikerojas87/pen/rojKb 
  */

$color-blue: #0b49a7;
$color-blue-dark: #FFF;
$submenu-width: 180px;


html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.menu-container {
  text-align: center;
}

nav {
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.nav {
  display: inline-block;
  margin: 2rem auto 0;
  background: $color-blue-dark;
  color: #fff;
  text-align: left;

  .Link {
    display: block;
    padding: 0 16px;
    line-height: inherit;
    cursor: pointer;
  }
}

.nav__menu {
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav__menu-item {
  display: inline-block;
  position: relative;

  &:hover {
    // background-color: $color-blue;

    .nav__submenu {
      display: block;
    }
  }
}

.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  left: 0px;
  // top: 0;
  width: 230px;
  background-color: $color-blue;
  border-radius: 20px;
  
}

// .nav__submenu-item {
//   // &:hover {
//   //   // background: rgba(#000, 0.1);
//   // }
// }